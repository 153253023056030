import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import LayoutB from "../components/LayoutB";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    height: inherit;
  }

  @media (min-width: 1366px) {
    justify-content: center;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    width: 50%;
    height: 480px;
    margin-right: 15px;
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    margin-right: 20px;
  }

  @media (min-width: 1366px) {
    width: 500px;
    height: 460px;
  }

  @media (min-width: 1440px) {
    width: 560px;
    height: 510px;
  }

  @media (min-width: 1920px) {
    margin-right: 30px;
    width: 640px;
    height: 560px;
  }
`;

const Title = styled.div`
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;

  @media (min-width: 1366px) {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  @media (min-width: 1440px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  @media (min-width: 1920px) {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  padding: 20px 15px;
  width: 100%;
  height: 100%;
  background-color: #1a1a24;

  @media (min-width: 1366px) {
    padding: 20px;
  }

  @media (min-width: 1920px) {
    padding: 30px;
  }
`;

const Info = styled.div`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 0.8rem;
  color: #cccccc;

  @media (min-width: 1440px) {
    margin-bottom: 20px;
    font-size: 0.9rem;
  }

  @media (min-width: 1920px) {
    font-size: 1rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > label {
    margin-bottom: 4px;
    font-size: 0.8rem;
    color: #398ab9;

    @media (min-width: 1440px) {
      margin-bottom: 5px;
      font-size: 0.9rem;
    }

    @media (min-width: 1920px) {
      margin-bottom: 6px;
      font-size: 1rem;
    }
  }

  & > input {
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 30px;

    @media (min-width: 1440px) {
      margin-bottom: 12px;
    }

    @media (min-width: 1920px) {
      margin-bottom: 15px;
    }
  }

  & > textarea {
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;

    @media (min-width: 1440px) {
      margin-bottom: 12px;
    }

    @media (min-width: 1920px) {
      margin-bottom: 15px;
    }
  }
`;

const Send = styled.button`
  border: none;
  border-radius: 5px;
  height: 30px;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 0.7rem;
  text-transform: uppercase;
  background-color: ${props => props.loading === "true" ? "#999999" : "#398ab9"};
  color: ${props => props.loading === "true" ? "#1a1a24" : "white"};

  @media (min-width: 1366px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  @media (min-width: 1920px) {
    height: 35px;
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
`;

const Location = styled.div`
  margin-bottom: 30px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1440px) {
    margin-bottom: 35px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 40px;
  }
`;

const City = styled.div`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;

  @media (min-width: 1440px) {
    margin-bottom: 6px;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }

  @media (min-width: 1920px) {
    margin-bottom: 7px;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`;

const Country = styled.div`
  margin-bottom: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 0.9rem;
  color: #398ab9;

  @media (min-width: 1440px) {
    margin-bottom: 9px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

const Address = styled.div`
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: #cccccc;

  @media (min-width: 1920px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const ContactoPage = () => {
  const [loading, setLoading] = useState(false);
  const data = useStaticQuery(graphql`
    {
      dataJson {
        ubicacion {
          ciudad
          direccion1
          direccion2
          direccion3
          id
          pais
          telefono
        }
      }
    }
  `);
  const ubicaciones = data.dataJson.ubicacion;

  return (
    <LayoutB pageTitle="Contáctanos">
      <Container>
        <Section>
          <Title>Contáctanos</Title>
          <Body>
            <Info>Complete la siguiente información y en breve un asesor se estará contactando con usted. Es obligatorio
              llenar todos los campos correctamente a fin de brindar solución a su consulta o duda. Gracias.</Info>
            <Form name="contact" method="POST" action="/success" netlify-honeypot="bot-field" data-netlify="true"
                  onSubmit={() => setLoading(true)}>
              <input type="hidden" name="bot-field"/>
              <input type="hidden" name="form-name" value="contact"/>
              <label htmlFor="name">Nombres y apellidos</label>
              <input id="name" name="name" type="text" required/>
              <label htmlFor="email">Correo electrónico</label>
              <input id="email" name="email" type="email" required/>
              <label htmlFor="phone">Número de celular</label>
              <input id="phone" name="phone" type="text" required/>
              <label htmlFor="message">Mensaje</label>
              <textarea id="message" name="message" rows="5" required/>
              <Send type="submit" loading={loading ? `true` : `false`}>
                {loading ? `Enviando...` : `Enviar`}
              </Send>
            </Form>
          </Body>
        </Section>
        <Section>
          <Title>Ubícanos</Title>
          <Body>
            {ubicaciones.map(ubicacion => {
              return (
                <Location key={ubicacion.id}>
                  <City>{ubicacion.ciudad}</City>
                  <Country>{ubicacion.pais}</Country>
                  <Address>
                    {ubicacion.direccion1}<br/>
                    {ubicacion.direccion2 !== "" && <>{ubicacion.direccion2}<br/></>}
                    {ubicacion.direccion3 !== "" && <>{ubicacion.direccion3}<br/></>}
                    {ubicacion.telefono !== "" && `Teléfono: ${ubicacion.telefono}`}
                  </Address>
                </Location>
              );
            })}
          </Body>
        </Section>
      </Container>
    </LayoutB>
  );
};

export default ContactoPage;